/**
 * Trap Focus
 * For ADA, prevents focusing outside of selected container
 */
function trapFocus(element, excludeAriaHidden = false) {
  /**
   * Init
   */
  function init() {
    trap();
  }

  /**
   * Trap
   */
  function trap() {
    const KEYCODE_TAB = 9; // Tab keycode
    let focusableEls = Array.prototype.slice.call(element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input:not([disabled]), select:not([disabled])')).filter((el) => !__.parents(el, 'ariaHidden'));

    if (excludeAriaHidden) {
      focusableEls = focusableEls.filter((el) => !__.parents(el, 'ariaHidden'));
    }

    let { length } = Object.keys(focusableEls);

    // Removes all DOM-hidden elements from focusableEls
    if (focusableEls.length > 1) {
      while (focusableEls[length - 1].offsetParent === null) {
        try {
          delete focusableEls[length - 1];
          length -= 1;
        } catch (e) {
          length -= 1;
        }
      }
    }

    const firstFocusableEl = focusableEls[0];
    const lastFocusableEl = focusableEls[length - 1];

    firstFocusableEl.focus();

    function addEvent(e) {
      const isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

      if (!isTabPressed) return;

      if (e.shiftKey && document.activeElement === firstFocusableEl) /* shift + tab */ {
        lastFocusableEl.focus();
        e.preventDefault();
      } else if (!e.shiftKey && document.activeElement === lastFocusableEl) /* tab */ {
        firstFocusableEl.focus();
        e.preventDefault();
      }
    }

    element.addEventListener('keydown', addEvent);
  }

  return init();
}

export default trapFocus;
